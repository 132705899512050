import * as _libsmKeycloak from 'KPackages/libsm-keycloak';

const queryParams = new URLSearchParams(location.search);
const keycloakParams = {
  url: location.protocol + '//' + process.env.VUE_APP_KEYCLOAK_DOMAIN,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: queryParams.get('public_client'),
}
// redirect_uri processato in keycloakManager
const keycloakManager = new _libsmKeycloak.KeycloakManager(keycloakParams)
const accountDomain = location.protocol + '//' + process.env.VUE_APP_ACCOUNT_DOMAIN

keycloakManager.init().then(function(authenticated) {
  if (authenticated === false) {
    window.location.href = accountDomain; 
  } 
});